import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['radioBtn']; // eslint-disable-line no-alert

  onSubmitForm(event) {
    event.target.form.submit();
  }

}

