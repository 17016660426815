import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  remove (evt) {
    let div = this.divIdToRemove(evt.target.id);
    console.log(div);
    div.classList.add('d-none');
  }

  divIdToRemove (buttonId) {
    const diese = '#';
    let divId = buttonId.replace('button-', 'div-');
    let divSelector = diese.concat(divId);
    let div = document.querySelector(divSelector);
    return div;
  }

}

