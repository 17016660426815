import Flatpickr from 'stimulus-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'

export default class extends Flatpickr {

  initialize() {
    this.config = {
      locale: French,
      altInput: true,
      altFormat: 'j F Y'
    };
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    if (instance.element.id === 'from_date') {
      flatpickr("#to_date", { locale: French, altInput: true, altFormat: 'j F Y', maxDate: Date.now(), minDate: dateStr })
    } else if (instance.element.id === 'to_date') {
      flatpickr("#from_date", { locale: French, altInput: true, altFormat: 'j F Y', maxDate: dateStr })
    }
    console.log(instance.element.id)
    console.log('the callback returns the selected dates', selectedDates)
    console.log('but returns it also as a string', dateStr)
    console.log('and the flatpickr instance', instance)
  }
}

