import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'source', 'button', 'lkdButton' ]

  connect() {
    $(this.buttonTarget).tooltip()
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  selectText (el) {
    let range = document.createRange()
    range.selectNodeContents(el)
    let selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }

  setTooltip (btn, message) {
    $(btn).tooltip('hide')
      .attr('data-original-title', message)
      .tooltip('show')
  }

  hideTooltip (btn) {
    setTimeout(function () {
      $(btn).tooltip('hide')
    }, 1000)
  }

  showTooltip (btn, message) {
    this.setTooltip(btn, message)
    this.hideTooltip(btn)
  }

  copy () {
    this.lkdButtonTarget.classList.remove("d-none")
    this.selectText(this.sourceTarget)
    try{
      document.execCommand('copy')
      this.showTooltip(this.buttonTarget, 'Nom copié !')
    }catch(e) {
      this.showTooltip(this.buttonTarget, 'Erreur dans la copie')
    }
  }
}
