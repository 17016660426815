import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form','nextbtn', 'previousbtn']; // eslint-disable-line no-alert

  connect () {
    document.querySelector(".form-control").focus();
    this.setNextButtonState();
    this.formTarget.addEventListener('input', this.onChange, true);
    this.checkButtons.forEach((checkBtn) => {
      checkBtn.addEventListener('click', this.onCheckBtnClick, true);
    });
    this.previousbtnTarget.addEventListener('click', this.onPrevClick, true);
  }

  get textFields () {
    const textFieldsSelectors = 'input[type="text"], input[type="email"], input[type="url"], input[type="tel"], input[type="number"]';
    return this.formTarget.querySelectorAll(textFieldsSelectors);
  }
  get checkButtons () {
    const checkButtonsSelectors = 'input[type="radio"], input[type="checkbox"]';
    return this.formTarget.querySelectorAll(checkButtonsSelectors);
  }

  onChange = (event) => {
    this.setNextButtonState();
  }

  onCheckBtnClick = (event) => {
    this.setNextButtonState();
  }

  onPrevClick = (event) => {
    if (this.isFormEmpty()) {
      this.element.setAttribute('novalidate', true);
    }
  }

  setNextButtonState () {
    if (this.isFormEmpty()) {
      this.disableNextButton();
    } else {
      this.enableNextButton();
    }
  }

  isFormEmpty () {
    if ( (this.fieldsEmpty(this.textFields)) && ( (this.checkButtonsNotChecked(this.checkButtons))) ) {
      return true;
    }  else {
      return false;
    }
  }

  fieldsEmpty (fields) {
    let empty = true;
    for (const field of fields) {
      const value = field.value.trim();
      if (value)
        empty = false;
    }
    return empty;
  }

  checkButtonsNotChecked (checkBtns) {
    let notChecked = true;
    for (const checkBtn of checkBtns) {
      if (checkBtn.checked)
        notChecked = false;
    }
    return notChecked;
  }

  disableNextButton () {
    this.nextbtnTarget.setAttribute('disabled', true);
  }

  enableNextButton () {
    this.nextbtnTarget.removeAttribute('disabled');
  }

}
