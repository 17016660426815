// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['inputCa', 'resellRate']

  connect() {
  }

  calculate() {
    if (!this.inputCa == null || !this.inputCa == "") {
      this.displayResult();
    }
  }

  get resellRateCalculation() {
    const newResellRate = 3;
    const newCa = this.inputCaInteger / parseFloat(this.resellRate) * newResellRate;
    return newCa;
  }
  get lossCalculation() {
    const result = this.resellRateCalculation - this.inputCaInteger;
    return result;
  }
  get inputCa() {
    return this.inputCaTarget.value;
  }
  get inputCaInteger() {
    return parseInt(this.inputCa);
  }
  get resellRate() {
    return this.resellRateTarget.value;
  }
  get caActuelInput() {
    return document.getElementById("caActuel");
  }
  get newCaText() {
    return document.getElementById("newCa")
  }
  get newCaInput() {
    return document.getElementById("newCaInput");
  }
  get lossText() {
    return document.getElementById("resellRateLoss");
  }
  numberToCurrency(number) {
    return number.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 });
  }
  displayResult() {
    this.caActuelInput.placeholder = this.numberToCurrency(this.inputCaInteger);
    this.newCaText.innerHTML       = this.numberToCurrency(this.resellRateCalculation);
    this.newCaInput.placeholder    = this.numberToCurrency(this.resellRateCalculation);
    this.lossText.innerHTML        = this.numberToCurrency(this.lossCalculation);
    const resellRateForm   = document.getElementById("resellRateForm");
    const resellRateResult = document.getElementById("resellRateResult");
    resellRateForm.classList.add('d-none');
    resellRateResult.classList.remove('d-none');
  }

}
