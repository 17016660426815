// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    const isMobile = window.matchMedia("only screen and (max-width: 350px)").matches;

    if (isMobile)
      this.replaceText();
  }

  get mobileText () {
    return this.data.get("value")
  }

  replaceText () {
    this.outputTarget.value = this.mobileText;
    this.outputTarget.text = this.mobileText;
  }
}

