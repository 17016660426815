document.addEventListener('turbolinks:load', () => {

  if(document.getElementById("player")) {

    const displayCtaBoxSeconds = $('#player').data('triggercta');
    const ctaBox               = document.getElementById('cta-box');

    const watchedSeconds = $('#player').data('triggerwatched');
    const pitchSeconds   = $('#player').data('triggerpitch');

    const videoId      = $('#player').data('videoid');
    const contentId    = $('#player').data('contentid');
    const subscriberId = $('#player').data('subscriberid');
    const startVideo   = $('#player').data('startvideo');

    const csrfToken = $('[name="csrf-token"]')[0].content;

    // 2. This code loads the IFrame Player API code asynchronously.
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.
    window.player      = null;
    window.onYouTubeIframeAPIReady = function() {
      player = new YT.Player('player', {
        videoId: videoId,
        playerVars: {
          autoplay: 0,
          controls: 0,
          start: startVideo,
          disablekb: 1,
          rel: 0,
          modestbranding: 1,
          origin: 'https://www.weloveruby.fr'
        },
        events: {
          'onStateChange': onPlayerStateChange
        }
      });
    }

    window.timer         = null;
    window.timeSpent     = [];
    window.videoDuration = null

    window.onPlayerStateChange = function(event) {
      window.videoDuration = player.getDuration();

      if(event.data == YT.PlayerState.PLAYING) {
        if(!timeSpent.length){
          for(let i=0, l=parseInt(player.getDuration()); i<l; i++) timeSpent.push(false);
        }
        timer = setInterval(record,1000);
      } else {
        clearInterval(timer);
      }
    }

    function record(){
      var currentPlayedTime = parseInt(player.getCurrentTime());
      timeSpent[ currentPlayedTime ] = true;

      // ctaBox
      if (currentPlayedTime >= displayCtaBoxSeconds) {
        ctaBox.classList.remove('d-none');
        showCountDown(currentPlayedTime);
      }

      // send info to controller
      switch (currentPlayedTime) {
        case watchedSeconds:
          sendToController(watchedSeconds);
          break;
        case pitchSeconds:
          sendToController(pitchSeconds);
          break;
        case (parseInt(videoDuration) - 5) :
          sendToController(videoDuration);
      }

      // send info when user quit page
      window.onbeforeunload = function(event) {
        var message = '';
        if (window.event) {
          sendToController(currentPlayedTime)
        }
        event = event || window.event;
        event.preventDefault = true;
        event.cancelBubble = true;
        event.returnValue = message;
      }
    }

    function showCountDown(currentPlayedTime) {
      var durationLeft     = Math.round(videoDuration - currentPlayedTime);

      var hours            = Math.floor((durationLeft / 60) / 60);
      var divHours         = document.getElementById('hours');
      divHours.innerHTML   = `<span class="time">${hours}</span><span class="label">heure</span>`;

      var minutes          = Math.floor(durationLeft / 60);
      if (minutes === 60) { minutes = 0; }
      var divMinutes       = document.getElementById('minutes');
      divMinutes.innerHTML = `<span class="time">${minutes}</span><span class="label">minutes</span>`;

      var seconds          = Math.floor(durationLeft % 60);
      var divSeconds       = document.getElementById('seconds');
      divSeconds.innerHTML = `<span class="time">${seconds}</span><span class="label">secondes</span>`;
    }

    function sendToController(seconds) {
      jQuery.ajax({
        data: { authenticity_token: csrfToken, seconds: seconds, contentid: contentId, subscriberid: subscriberId },
        dataType: 'script',
        type: 'post',
        url: "/content_views/update"
      });
    }

  }
})
