// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'inputRevenue',
    'inputEmailRevenuePart',
    'actualEmailPartText',
    'actualAdsPartText',
    'resultEmailPartText',
    'resultAdsPartText',
    'actualRevenueInput',
    'newRevenueInput',
    'lossRevenueText'
  ]

  connect() {
  }

  calculate() {
    if (this.inputRevenueTarget.validity.valid) {
      this.displayResult();
    }
  }

  get newRevenueCalculation() {
    const result = this.revenue * 1.5;
    return result;
  }
  get lossCalculation() {
    const result = this.newRevenueCalculation - this.revenue;
    return result;
  }

  get revenue() {
    return parseFloat(this.inputRevenueTarget.value);
  }
  get emailRevenuePart() {
    return parseFloat(this.inputEmailRevenuePartTarget.value) / 100;
  }

  get actualEmailPart() {
    const result = this.revenue * 10 / 100;
    return result;
  }
  get actualAdsPart() {
    const result = this.revenue * 90 / 100;
    return result;
  }
  get resultEmailPart() {
    const result = this.revenue * 40 / 100;
    return result;
  }

  numberToCurrency(number) {
    return number.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 });
  }

  displayResult() {
    this.actualEmailPartTextTarget.innerHTML = this.numberToCurrency(this.actualEmailPart);
    this.actualAdsPartTextTarget.innerHTML   = this.numberToCurrency(this.actualAdsPart);
    this.resultEmailPartTextTarget.innerHTML = this.numberToCurrency(this.resultEmailPart);
    this.resultAdsPartTextTarget.innerHTML   = this.numberToCurrency(this.actualAdsPart);
    this.actualRevenueInputTarget.value      = this.numberToCurrency(this.revenue);
    this.newRevenueInputTarget.value         = this.numberToCurrency(this.newRevenueCalculation);
    this.lossRevenueTextTarget.innerHTML     = this.numberToCurrency(this.lossCalculation);
    const underUsedEmailsForm   = document.getElementById("underUsedEmailsForm");
    const underUsedEmailsResult = document.getElementById("underUsedEmailsResult")
    underUsedEmailsForm.classList.add("d-none");
    underUsedEmailsResult.classList.remove("d-none");
  }

}
