import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['radioBtn']; // eslint-disable-line no-alert

  onSubmitForm(event) {
    let fieldToUpdate = this.radioBtnTarget.name;
    let value         = this.radioBtnTarget.checked;
    let formData      = new FormData();
    formData.append(fieldToUpdate, value);

    Rails.ajax({
      url: this.data.get("update-url"),
      type: "PATCH",
      dataType: 'script',
      data: formData
    })
  }

}

