// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['display']

  connect() {
  }

  render (evt) {
    this.displayTarget.innerHTML = evt.detail[0].body.innerHTML
  }

}
