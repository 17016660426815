// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'selectAllCbx', 'btn', 'cbx' ]

  connect() {
    this.btnTarget.disabled = true;
    this.cbxTargets.forEach((cbx) => {
      cbx.checked = false;
      cbx.addEventListener('click', this.toggleButtonDisability, true);
    })
  }

  toggleCheckBoxes(e) {
    this.cbxTargets.forEach((cbx) => {
      if (cbx != this.selectAllCbxTarget)
        cbx.checked = this.selectAllCbxTarget.checked;
    });
    this.toggleButtonDisability();
  }

  toggleButtonDisability = () => {
    if (this.cbxTargets.some(x => x.checked)){
      this.btnTarget.disabled = false;
    } else {
      this.btnTarget.disabled = true;
    }
  }
}
