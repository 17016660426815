import { Controller } from "stimulus"
import ahoy from "ahoy.js";

export default class extends Controller {

  connect() {
    // console.log('coucou')
  }

  saveClickedLink() {
    const clickedLink = event.currentTarget.href
    ahoy.track("click", clickedLink);
  }

  saveClickedButton() {
    ahoy.track("click", this.data.get("value"));
  }
}
