// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    setTimeout(() => this.urlRedirect(this.redirectionUrl), this.milliSeconds)
  }

  get redirectionUrl () {
    return this.data.get("url")
  }

  get milliSeconds () {
    return this.seconds * 1000
  }

  get seconds () {
    return parseInt(this.data.get("seconds"))
  }

  urlRedirect = (url) => {
    window.location = url
  }
}
