// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'inputRevenue',
    'inputConversionRate',
    'newConversionRateText',
    'actualConversionRateText',
    'newRevenueText',
    'actualRevenueInput',
    'newRevenueInput',
    'lossRevenueText'
  ]

  connect() {
  }

  calculate() {
    if (this.inputRevenueTarget.validity.valid && this.inputConversionRateTarget.validity.valid) {
      this.displayResult();
    }
  }

  get newRevenueCalculation() {
    const revenueConversionRate = this.revenue / this.conversionRate;
    const result                = revenueConversionRate * this.newConversionRate;
    return result;
  }
  get lossCalculation() {
    const result = this.newRevenueCalculation - this.revenue;
    return result;
  }

  get revenue() {
    return parseInt(this.inputRevenueTarget.value);
  }
  get conversionRate() {
    return parseFloat(this.inputConversionRateTarget.value) / 100;
  }
  get newConversionRate() {
    return this.conversionRate + 2 / 100;
  }

  numberToCurrency(number) {
    return number.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 });
  }

  displayResult() {
    this.newConversionRateTextTarget.innerHTML    = (this.newConversionRate * 100).toFixed(1);
    this.actualConversionRateTextTarget.innerHTML = this.conversionRate * 100;
    this.newRevenueTextTarget.innerHTML           = this.numberToCurrency(this.newRevenueCalculation);
    this.actualRevenueInputTarget.value           = this.numberToCurrency(this.revenue);
    this.newRevenueInputTarget.value              = this.numberToCurrency(this.newRevenueCalculation);
    this.lossRevenueTextTarget.innerHTML          = this.numberToCurrency(this.lossCalculation);
    const conversionRateForm   = document.getElementById("conversionRateForm");
    const conversionRateResult = document.getElementById("conversionRateResult")
    conversionRateForm.classList.add("d-none");
    conversionRateResult.classList.remove("d-none");
  }

}
