document.addEventListener('turbolinks:load', () => {

  // EXIT-POPUP
  function onMouseOut(event) {
    // If the mouse is near the top of the window, show the popup
    // Also, do NOT trigger when hovering or clicking on selects (bug firefox)
    if (
      event.clientY < 50 &&
      event.relatedTarget == null &&
      !(event.target.nodeName.toLowerCase() == 'input' && event.target.getAttribute('type').toLowerCase() == 'email' ) &&
      event.target.nodeName.toLowerCase() !== 'select') {
      // Remove this event listener
      document.removeEventListener("mouseout", onMouseOut);

      // Show the popup
      $('#exit-popup').modal('show');
    }
  }

  if ( ($('.pages.index')) && ($('.modal')) ) {
    document.addEventListener("mouseout", onMouseOut);
  }

  // COOKIECONSENT
  if (!localStorage.getItem('cookieconsent')) {
    document.body.innerHTML += '\
        <div class="cookieconsent">\
          En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies. \
          <a class="btn btn-primary" href="#">OK</a>\
        </div>\
        ';
    document.querySelector('.cookieconsent a').onclick = function(e) {
      e.preventDefault();
      document.querySelector('.cookieconsent').style.display = 'none';
      localStorage.setItem('cookieconsent', true);
    };
  }

});
