// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('jquery')
require('jquery-easing')
require('bootstrap')
require('@fortawesome/fontawesome-free')

import 'fontsource-roboto-condensed/latin-ext-700.css'
import 'fontsource-roboto-condensed/latin-ext-400.css'
// import 'fontsource-roboto/latin-ext-300.css'
// import 'fontsource-roboto/latin-ext-400.css'
// import 'fontsource-roboto/latin-ext-500.css'
// import 'fontsource-roboto/latin-ext-700.css'
import '@fontsource/lato/latin-300.css'
import '@fontsource/lato/latin-300-italic.css'
import '@fontsource/lato/latin-400.css'
import '@fontsource/lato/latin-400-italic.css'
import '@fontsource/lato/latin-700.css'
import '@fontsource/lato/latin-700-italic.css'
import '@fontsource/josefin-sans/latin-200.css'
import '@fontsource/josefin-sans/latin-400.css'
import '@fontsource/josefin-sans/latin-400-italic.css'
import '@fontsource/josefin-sans/latin-700.css'
import '@fontsource/josefin-sans/latin-700-italic.css'
import '@fontsource/josefin-slab/latin-700.css'

require('../js/app')
require('../styles/app')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "controllers"

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})
